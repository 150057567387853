import { useEffect, useState } from 'react';
import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap';
import api from '../../service/api';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { accordionClick } from '../../redux/accordion/actions'
import { Link, useParams } from 'react-router-dom';
import logo from '../../image/clube-do-gas.png'
import { configurationTheme } from '../../redux/configurationTheme/actions'

const Header = () => {
    const [address, setAddress] = useState();
    const [address2, setAddress2] = useState();
    const [logoMenu, setLogoMenu] = useState(logo);
    const [color, setColor] = useState('#26398C');

    var db = window.location.pathname;

    const dispatch = useDispatch();

    const { currentTheme } = useSelector(rootReducer => rootReducer.configurationReducer);

    useEffect(() => {
        api
            .get("/Configuracoes" + db)
            .then((response) => {
                response.data.map(key => {
                    setLogoMenu(key['logoMenu']);
                    dispatch(configurationTheme({ logoLogin: key['logoLogin'], logoMenu: key['logoMenu'], colorPrimary: key['colorPrimary'], colorSecundary: key['colorSecundary'] }));
                })
            })

            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    useEffect(() => {
        api
            .get('/TodosPerfilRevendas' + db)
            .then(response => {
                setAddress(response.data[0].address + ', ' + response.data[0].number)
                setAddress2(response.data[0].neighborhood + ' - ' + response.data[0].city + '/' + response.data[0].state);
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    // console.log(db)
    return (
        <Navbar expand="lg" className="navBar header" >
            <Container style={{ justifyContent: 'space-between', width: "40%" }}>
                <img src={logoMenu} width={87} />
                {db !== '/expresso_gas_' && db !== '/ats_telegas' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: 'white', fontSize: 12, fontWeight: 400 }}>{address}</span>
                        <span style={{ color: 'white', fontSize: 12, fontWeight: 400 }}>{address2}</span>
                        {/* <span style={{ color: 'white', fontSize: 12, fontWeight: 400 }}>Entrega 60 min</span> */}
                    </div>
                ) : ('')}
            </Container>
        </Navbar>
    )
}

export default Header